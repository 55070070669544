document.addEventListener('turbo:load', () => {
    const showButton = document.querySelector('.show-more__button');
    if(showButton == null) { return }

    showButton.addEventListener('click', () => {
        if (showButton.innerText === 'SHOW MORE') {
            showButton.innerText = 'SHOW LESS';
        } else {
            showButton.innerText = 'SHOW MORE';
        }
        document.querySelectorAll('.team__member').forEach(item => {
            if (window.getComputedStyle(item).display === 'none' || item.classList.contains('team__member--visible')) {
                item.classList.toggle('team__member--visible');
            }
        })
    })
});

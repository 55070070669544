import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = ["source"]

  connect() {}

  copy(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.sourceTarget.dataset.clipboardText);
    let currentHTML = this.sourceTarget.innerText
    this.sourceTarget.innerHTML = "Copied!";
    setTimeout(() => {
      this.sourceTarget.innerHTML = currentHTML;
    }, 2000)
  }
}

import { Controller } from "@hotwired/stimulus"
import 'leaflet'
// Connects to data-controller="map"
export default class extends Controller {
    static targets = ['container']

    connect() {
        if(!this.hasContainerTarget) return;
        this.map = L.map(this.containerTarget.id).setView([53.1539100404337, 23.182295635414334], 14)
        const mapLayer = () => {
            return L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            })
        }
        mapLayer().addTo(this.map);
        this.addOffices();
    }

    addOffices(){
        let LeafIcon = L.Icon.extend({
          options: {
            iconSize: [32,32],
          }
        });
        const mapIcon = new LeafIcon({iconUrl: '/map-pin.svg'});
        const offices = [
            { lat: 53.1539100404337, lng: 23.182295635414334, title: 'Main Office' },
            // { lat: 53.16058073550836, lng: 23.204346643163326, title: 'Second Office' },
        ]
        offices.forEach((office) => {
            let marker = L.marker(
                [ office.lat, office.lng ],
                {title: office.title, icon: mapIcon}
            ).addTo(this.map)
        })
    }
}

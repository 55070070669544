const elements = Array.from(document.querySelectorAll('.post-content p img'));
elements.forEach(function(el) {
    let closestElement = el.closest('p');
    closestElement.classList.add('article__image');
});

const disqus_config = function () {
    this.page.url = window.request_url;
    this.page.identifier = window.post_id;
};

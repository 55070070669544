import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["wrapper", "body", "notClose"]

  connect() {
    enter(this.wrapperTarget)
    enter(this.bodyTarget)

    document.addEventListener('turbo:submit-end', this.handleSubmit)
  }

  disconnect() {
    document.removeEventListener('turbo:submit-end', this.handleSubmit)
  }

  close() {
    leave(this.wrapperTarget)
    leave(this.bodyTarget).then(() => {
      this.element.remove()
    })

    this.element.closest("turbo-frame").src = undefined
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      this.close()
    }
  }

  handleSubmit = (e) => {
    if(this.hasNotCloseTarget)
      return

    if (e.detail.success) {
      this.close()
    }
  }
}

import { Application } from "@hotwired/stimulus"
import 'alpine-turbo-drive-adapter'
import Alpine from 'alpinejs'
import {scrollHandler} from "@/javascripts/case_studies/show";

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application
window.Alpine = Alpine

Alpine.data('scrollHandler', scrollHandler)

Alpine.start()

export { application }

import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash"

export default class extends Controller {
  static targets = ["form", "value" ]

  filter({params: {filterBy}}) {
    this.valueTarget.value = filterBy
    this.formTarget.requestSubmit()
  }

  initialize() {
    this.debouncedSubmit = debounce(this.submit, 250).bind(this);
  }

  async submit() {
    try {
      await this.formTarget.requestSubmit();
    } catch (e) {
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.debouncedUpdate = debounce(this.update, 400).bind(this);
  }

  update() {
    const inputContent = this.inputTarget.value
    const previewTarget = document.querySelector('[data-blog-post-target="preview"]')

    fetch("/admin/blog_post_preview", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ content: inputContent })
    })
      .then(response => response.json())
      .then(data => previewTarget.innerHTML = data.preview)
      .catch(error => console.error("Error:", error))
  }
}
